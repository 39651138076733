
.ReactTable {
  border-radius: .28571429rem;

  .rt-thead {
    &.-header {
      box-shadow: none !important;
      border-bottom: 1px solid rgba(34, 36, 38, .15);
      }

    .rt-tr {
      text-align: left !important;

      .-cursor-pointer {
        padding: 0.7em;
        background: #f7f7f7;
      }
    }
  }

  .-pagination {
    box-shadow: none !important;
  }
}

.first-col-unset .rt-td:nth-child(1) {
  white-space: unset;
}
