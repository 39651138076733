
.horizontal-datepicker {

  .slide-container {
    display: flex;

    div {
      flex: 1;
      margin: 0.5rem;
      border: 1px solid $brandingPrimary;
      border-radius: 3px;
      padding: 0.4rem;
      text-align: center;
      cursor: pointer;
      user-select: none;
    }

    div.available {
      border: 1px solid $brandingHighlight;
      background-color: $brandingHighlight;
    }

    div.active {
      border: 1px solid $brandingPrimary;
      background-color: $brandingPrimary;
      color: #fff;
      cursor: default;

      h3 {
        // Override semantic ui
        font-weight: bold !important;
        color: #fff;
      }
    }
  }

  .icon-column {
    // Override semantic ui
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  i.chevron {
    cursor: pointer;
    color: $brandingPrimary;
  }

  .calendar-container {
    font-size: 1.15em;
    margin-bottom: 1.5rem;

    i {
      margin-left: 0.5rem;
      font-size: 1.5em;
    }

    .react-datepicker-wrapper {
      cursor: pointer;
    }
  }
}

.timeslot-picker {
  margin-top: 1.5rem;

  .timeslot {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 1px solid $brandingPrimary;
    border-radius: 3px;
    padding: 0.5rem;
    cursor: pointer;
    user-select: none;
  }

  .timeslot.active {
    background-color: $brandingPrimary;
    color: #fff;
  }

  .timeslot.disabled {
    opacity: 0.5;
    background-color: #ccc;
    color: #000;
    cursor: not-allowed;
  }

  h3 {
    margin-bottom: 0;
  }
}

.timeslot-picker.error {
  padding: 3rem 0;
  font-size: 1.25em;
}

@media only screen and (max-width: 767px) {

  .horizontal-datepicker {
    .icon-column {
      // Override semantic ui
      padding-right: 0 !important;
      // Override semantic ui
      padding-left: 0 !important;
    }

    i.chevron.huge {
      margin: 0 auto;
      font-size: 2em;
    }

    .slide-container div:nth-child(1),
    .slide-container div:nth-child(2),
    .slide-container div:nth-child(6),
    .slide-container div:nth-child(7) {
      display: none;
    }
  }
}
