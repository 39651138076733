
.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

/**
 * Display
 */
.block {
  display: block;
}

.hidden {
  display: none;
}

/**
 * Floats
 */
.float-right {
  float: right;
}

.clear {
  clear: both;
}


/**
 * Spacing
 * Spacing classes can use !important to override any other styles
 */

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt {
  margin-top: 1rem !important;
}

.mt-2x {
  margin-top: 2rem;
}

.mt-3x {
  margin-top: 3rem;
}

.mt-4x {
  margin-top: 4rem;
}

.mb {
  margin-bottom: 1rem !important;
}

.mb-2x {
  margin-bottom: 2rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-half {
  margin-bottom: 0.5rem !important;
}

.p-0 {
  padding: 0;
}
