
.richtextarea-editor {
  margin: 0;
  tap-highlight-color: rgba(255,255,255,0);
  padding: .78571429em 1em;
  background: #fff;
  border: 1px solid rgba(34,36,38,.15);
  outline: 0;
  color: rgba(0,0,0,.87);
  border-radius: .28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  -webkit-transition: color .1s ease,border-color .1s ease;
  transition: color .1s ease,border-color .1s ease;
  font-size: 1em;
  line-height: 1.2857;
  resize: vertical;
}

.input-helptext {
  position: relative;
  display: block;
  color: #757575;
}
