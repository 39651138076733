
.KPIMetric {

  h5 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
  }


  .KPIMetric_current {
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    padding-top: 10px;
  }

  .KPIMetric_positive {
    color: green;
  }
  .KPIMetric_negative {
    color: red;
  }
  .KPIMetric_neutral {
    color: $brandingPrimary;
  }

}
@media (max-width: 767px) {
  .ui.table.definition {
    tr {
      padding-top: 0;
    }
  }
}
