
.sidebarBasic {
  height: 100vh;
  width: 80px;
  display: inline-block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.sidebar {
  @extend .sidebarBasic;
  padding-top: 50px;
  background-color: #022e47;
  color: #fff;
  -webkit-transition: width .2s;
  transition: width .2s;
  will-change: width;
}

.sidebar:hover {
  width: 260px;
}

.sidebarInner {
  overflow-x: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100%;
}

.accordionTitle {
  width: 300px;
  padding: 18px 0;
  color: #fff;
  display: block;
}

.accordionTitle:focus,
.accordionTitle:hover {
  color: #fff;
}

.accordionTitle i {
  margin-left: 25px;
  font-size: 20px;
}

.sidebar :global(.open-intercom) {
  cursor: pointer;
}

@media(max-width: 750px) {
  .sidebar,
  .sidebarBasic {
    width: 100%;
    left: -100%;
    transition: .45s;
  }

  .sidebar:hover,
  .sidebar:focus {
    width: 100%;
  }

  .sidebar * {
    opacity: 1 !important;
  }

  .sidebar:global(.open) {
    left: 0;
    /* To stay above inline manual */
    z-index: 10000;
  }
}
 