
#intercom-css-container,
#intercom-container {
  height: auto;
}

@media only screen and (max-width: 1024px) {
  // Important to override Intercom styling
  #intercom-container .intercom-app .intercom-launcher-frame,
  .intercom-lightweight-app-launcher {
    bottom: 60px !important;
  }
}
