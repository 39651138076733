
// Body
$body-bg: #f5f8fa;


// Colours
$brandingPrimary: #022e47;
$brandingSecondary: #fc4553;
$brandingHighlight: #00ffcc;

$textColor: #000;
$success: #21ba45;
