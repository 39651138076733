
.content {
  width: calc(100% - 80px);

  @media (min-width: 1280px) {
    width: calc(100% - 280px);
  }
  
  float: right;
  padding-top: 40px;
  min-height: 100vh;
}

.bottomnav {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

@media (max-width: 1024px) {
  .content {
    width: 100%;
    padding-bottom: 56px; // 56 - Height of "MobileBottomNavigation" component
  }
}

@media (max-width: 768px) {
  .main {
    padding-bottom: 100px;
  }
}

@media(max-width: 750px) {
  .content {
    width: 100%;
    float: none;
  }
}
