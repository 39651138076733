
.ui {

  /****
   * Labels
   */
  .field > .label {
    background: none;
    padding-left: 0;
  }

  /*****
   * Buttons
   */
  &.primary.button {
    background: #022E47;
    color: #fff;

    &:hover, &:active, &:focus {
      background: $brandingSecondary;
    }
  }

  &.secondary.button {
    background: #00FFCC;
    color: #022E47;

    &:hover, &:active, &:focus {
      background: darken(#00FFCC, 10%);
    }
  }


  &.negative.button {
    background: #FC4553;

    &:hover, &:active, &:focus {
      background: darken(#FC4553, 10%);
    }
  }

  /*****
   * Grid system
   */
  .collapsed.row.column { padding: 0; }


  /*****
   * Table
   */
  .table {
    td.quarter {
      width: 35%;
    }
  }

  .ribbon.label {
    left: calc(-1rem - .9em);
    padding-left: calc(1rem + .9em);
  }


  /*****
   * Forms / Fields
   */
  //&.form .field.required input[type=text],
  //&.form .field.required input[type=email],
  //&.form .field.required input[type=tel]{
  //  border-color: #e0b4b4;
  //  background: #fff6f6;
  //}

  /*****
   * Segment
   */
  .segment {
    border-radius: 0;
  }

  /*****
   * Steps
   */
  // Container
  .steps {
    border: 0;
    width: 100%;

    .step,
    .step.active,
    .step.disabled,
    .step.completed {
      border: 0;
      background: transparent;

      .title {
        color: $textColor;
      }
    }

    .step.disabled {
      opacity: .3;
    }

    .step:after,
    .step.active:after {
      background: transparent;
      border-color: #777;
    }
  }

  .steps.ordered {
    margin-top: 1rem;
    margin-bottom: 2rem;

    .step:before {
      height: 40px;
      width: 40px;
      line-height: 20px;
      font-size: 1.75em;
      padding: .75rem;
      border-radius: 50%;
      color: #fff;
      background-color: $brandingSecondary;
    }

    .step.completed:before,
    .step.completed>.icon:before {
      background-color: transparent;
      //border: 2px solid $brandingSecondary;
      padding-left: .5rem;
      padding-top: .7rem;
      color: $success;
    }

    .step.active:before {
      color: #fff;
    }
  }
}

@media only screen and (max-width: 991px) {
  .ui {
    .steps.ordered {
      margin-top: 0;
      font-size: 1.2em;

      .step > .content {
        display: none;
      }
    }

    [class*="tablet stackable"].steps {
      flex-direction: row;
    }
  }
}

@media only screen and (max-width: 767px) {
  .ui {
    .steps.ordered {
      // !important flag overrides Semantic UI
      .step {
        display: inline-block !important;
        width: 20% !important;
        padding-top: 0.5em !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    }

    .ui.steps:not(.unstackable) {
      flex-direction: row;
    }

    .ui .steps.ordered .step:before {
      margin: 0 auto;
    }
  }

  // Overqualifying to beat the library
  body .ui.modal.transition.visible {
    margin-top: 20px !important;
  }
}

// Fix for modals being off screen
.ui.page.modals.transition.visible {
  display: flex !important;
}

.ui.modal.transition.visible {
  margin-top: inherit !important;
}

.ui.form .warning.message {
  display: block !important;
}

body a {
  color: #0d69db;
}

.inmplayer-general {
  height: auto;
}

.inmplayer-panel {
  z-index: 100000 !important;
}