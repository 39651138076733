.rbc-mobile-toolbar-label {
  text-align: center;
  padding: 0 10px 10px 10px;
  font-weight: bold;
  font-size: 15px;
}

.rbc-mobile-toolbar .rbc-toolbar button {
  padding: .375rem 0.5rem;
}

.rbc-toolbar button {
  text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
  div {
    .rbc-btn-group {
      display: block;
      margin-top: 7px;
      margin-left: 0 !important;
    }

    .rbc-btn-group button {
      width: 33%;
    }

    .rbc-toolbar {
      display: block;
    }
  }
}
